export default defineNuxtPlugin({
  name: "sentry",
  parallel: true,

  async setup(nuxtApp) {
    const { init, browserTracingIntegration, replayIntegration } = await import(
      "@sentry/vue"
    );

    const {
      public: { sentry },
    } = useRuntimeConfig();
    const router = useRouter();

    if (!sentry.dsn) {
      return;
    }

    /*Sentry.init({
    app: nuxtApp.vueApp,
    autoSessionTracking: true,
    debug: sentry.environment !== "production",
    dsn: sentry.dsn,
    environment: sentry.environment,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
      Sentry.captureConsoleIntegration(),
    ],
    tracePropagationTargets: [
      "localhost",
      "https://app-staging.getgrain.se",
      "https://app.getgrain.se",
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0, // Change in prod
    replaysOnErrorSampleRate: 1.0, // Change in prod if necessary
  });*/
    //globalThis._sentryEsmLoaderHookRegistered = true;
    init({
      app: nuxtApp.vueApp,
      autoSessionTracking: true,
      debug: sentry.environment !== "production",
      dsn: sentry.dsn,
      environment: sentry.environment,
      integrations: [
        browserTracingIntegration({ router }),
        replayIntegration({
          mutationBreadcrumbLimit: 1000,
          mutationLimit: 1500,
          maskAllText: false, // Masks all text to reduce the amount of data being processed
          blockAllMedia: false,
          block: ["iframe"],
        }),
        //Sentry.replayIntegration(),
        //Sentry.captureConsoleIntegration(),
        /* Sentry.replayIntegration({
          // Adjust the replay settings
          maskAllText: true, // Masks all text to reduce the amount of data being processed
          blockAllMedia: true, // Blocks all media elements to reduce load
        }),*/
      ],
      tracePropagationTargets: [
        "localhost",
        "https://app-staging.getgrain.se",
        "https://app.getgrain.se",
      ],
      tracesSampleRate: 0.2,
      replaysSessionSampleRate: 1.0,
      replaysOnErrorSampleRate: 1.0, // Adjust this based on necessity
    });

    /* nuxtApp.hook("app:mounted", async () => {
      const { addIntegration, replayIntegration } = await import("@sentry/vue");
      addIntegration(
        replayIntegration({ maskAllText: false, blockAllMedia: false })
      );
    });*/

    /* nuxtApp.vueApp.config.errorHandler = (err, context) => {
    withScope((scope) => {
      scope.setExtra("context", context);
      Sentry.captureException(err);
    });
  };

  nuxtApp.hook("app:error", (err) => {
    Sentry.captureException(err);
  });*/
  },
});
